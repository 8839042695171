import React, { createContext, useContext, useReducer } from 'react'
const apiURL = process.env.GATSBY_STRAPI_API_URL
const localStorageKey = "wsg_user_payload"
const DEFAULT_STATE = {
  jwt: null,
  user: {},
  loggedIn: false
}

const parseJwt = token => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const getJWT = () => {
  const data = window.localStorage.getItem(localStorageKey)
  if (data) {
    return JSON.parse(data)?.jwt
  }
  else {
    return ''
  }
}

const writeToLocalStorage = payload => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(payload))
}

const reducer = (state, action) => {
  switch(action.type){
        case 'LOGIN': 
            const { jwt = null, user = {} } = action.payload
            writeToLocalStorage(action.payload)
            return {...state, jwt, user, loggedIn: true}
        case 'LOGOUT':
            window.localStorage.removeItem(localStorageKey)
            return {...state, jwt: null, user: {}, loggedIn: false}
        case 'READ_USER_LOCAL':
            const data = JSON.parse(window.localStorage.getItem(localStorageKey))
            const token = parseJwt(data.jwt)
            const expiry = token.exp
            if (expiry <= Date.now()) {
                window.localStorage.removeItem(localStorageKey) 
                return DEFAULT_STATE
            }
            return {...state, jwt, user, loggedIn: true}
        default:
            return DEFAULT_STATE
  }
}

const getLocalAuth = () => {
    const data = JSON.parse(window.localStorage.getItem(localStorageKey))
    if (data) {
        try {
            const token = parseJwt(data.jwt)
            const expiry = token.exp
            if (expiry <= Date.now() / 1000) { // jwt exp is in seconds
                console.log("Expired token - deleting")
                window.localStorage.removeItem(localStorageKey) 
                return DEFAULT_STATE
            } else {
                return {...data, loggedIn: true}
            }
        } catch (e) {
            console.log("Issue parsing token")
            return DEFAULT_STATE
        }
    }
    return DEFAULT_STATE
}

const AuthContext = createContext('')

const AuthProvider = ({ children }) => {
    return (
        <AuthContext.Provider value={useReducer(reducer, getLocalAuth())}>
            {children}
        </AuthContext.Provider>
    )
}

export const wrapRootElement = ({element}) => {
    return (
      <AuthProvider>
        {element}
      </AuthProvider>
    )
}


const useAuth = () => {
  const [state, dispatcher] = useContext(AuthContext)
  // load from localstorage
  const isAuthenticated = state?.loggedIn && Object.keys(state?.user).length

  const login = async (credentials) => new Promise(async(resolve, reject) => {
    try{
      const response = await fetch(`${apiURL}/api/auth/local`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(credentials)
      })
      if (response.status == 200) {
        const payload = await response.json()
        dispatcher({ type: 'LOGIN', payload })
        resolve(payload)
      } else {
        throw new Error("Invalid credentials. Check email / password or click 'Forgot your pasword'.")
      }
    }
    catch(e){
      console.log(e)
      reject(e)
    }
  })
  const logout = () => {
    dispatcher({ type: 'LOGOUT' })
  }

  return { state, isAuthenticated, login, logout }
}

export default useAuth
